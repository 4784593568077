const SvgSass = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720.78 720.5">
            <g>
                <g>
                <g>
                    <path class="sass-dark" d="M293,471.34c16.88,53.44-21.09,94.22-50.62,102.66-35.16,11.25-33.76-11.25-32.35-19.69,4.22-23.9,23.91-53.43,82.97-82.97Z"/>
                    <path class="sass-dark" d="M630.57.25H90.43C40.9.25.5,40.68.5,90.25v540c0,49.57,40.4,90,89.93,90l540.14-.28c49.53,0,89.93-40.43,89.93-90V90.25C720.5,40.68,680.1.25,630.57.25ZM574.25,190.09c-9.84,149.07-237.66,181.41-265.78,111.1-5.63-15.47,4.22-11.25,7.03-5.63,53.44,50.63,205.31-8.44,222.19-95.62,11.25-57.66-77.35-82.97-202.5-19.69-125.16,63.28-132.19,113.91-133.6,133.59-2.81,53.44,60.47,75.94,106.88,125.16,80.15-21.09,144.84,7.03,144.84,67.5,0,23.91-21.09,37.97-30.93,40.78-9.85,2.81-19.69,0-4.22-5.62,15.46-5.63,37.97-40.78-5.63-71.72-11.25-8.44-49.22-22.5-92.81-11.25,19.69,57.65-11.25,112.5-42.19,132.19-30.94,19.68-104.06,40.78-106.87-26.72-.71-24.89,14.75-46.9,36.58-65.85,21.34-18.53,48.76-34.14,73.1-46.65-37.96-29.53-122.34-74.53-130.78-130.79-8.44-56.25,78.75-150.46,213.75-196.87,99.85-33.75,213.75-12.66,210.94,66.09Z"/>
                </g>
                    <path class="icon-white" d="M363.31,124c-135,46.41-222.19,140.62-213.75,196.87,8.44,56.26,92.82,101.26,130.78,130.79-24.34,12.51-51.76,28.12-73.1,46.65-21.83,18.95-37.29,40.96-36.58,65.85,2.81,67.5,75.93,46.4,106.87,26.72,30.94-19.69,61.88-74.54,42.19-132.19,43.59-11.25,81.56,2.81,92.81,11.25,43.6,30.94,21.09,66.09,5.63,71.72-15.47,5.62-5.63,8.43,4.22,5.62,9.84-2.81,30.93-16.87,30.93-40.78,0-60.47-64.69-88.59-144.84-67.5-46.41-49.22-109.69-71.72-106.88-125.16,1.41-19.68,8.44-70.31,133.6-133.59,125.15-63.28,213.75-37.97,202.5,19.69-16.88,87.18-168.75,146.25-222.19,95.62-2.81-5.62-12.66-9.84-7.03,5.63,28.12,70.31,255.94,37.97,265.78-111.1,2.81-78.75-111.09-99.84-210.94-66.09ZM242.38,574c-35.16,11.25-33.76-11.25-32.35-19.69,4.22-23.9,23.91-53.43,82.97-82.97,16.88,53.44-21.09,94.22-50.62,102.66Z"/>
                </g>
            </g>
        </svg>
    );
};

export default SvgSass;