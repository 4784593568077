const SvgTailwind = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 721.05 720.5">
            <g>
                <path class="tailwind-light" d="M630.57.25H90.43C40.9.25.5,40.68.5,90.25v540c0,49.57,40.4,90,89.93,90l540.14-.28c49.53,0,89.93-40.43,89.93-90V90.25C720.5,40.68,680.1.25,630.57.25ZM496.6,442.52c-7.63,20.12-18.2,38.41-33.7,53.58-17.19,16.81-37.84,27.26-61.23,32.28-28.41,6.1-56.86,5.93-85.19-.63-26.17-6.05-48.41-19.33-68.12-37.24-11.14-10.13-21.4-21.15-32.23-31.59-8.78-8.47-17.85-16.7-29.07-21.7-29.2-12.99-57.23-10.48-83.72,7.53-10.6,7.21-19.39,16.35-27.39,26.29-.55.69-1.11,1.39-1.7,2.06-.13.15-.37.2-1.19.6,11.02-40.82,29.65-75.62,68.01-96.53,15.23-8.3,31.65-12.96,48.82-15.17,12.63-1.63,25.28-2.01,38.03-1.44,35.99,1.6,67.85,13.51,95.28,37.01,11.02,9.45,21.01,19.95,31.29,30.15,8.4,8.32,16.87,16.57,26.82,23.07,27.63,18.06,63.34,17.27,91.61-1.99,10.97-7.48,20.15-16.84,28.41-27.15.67-.83,1.11-1.93,2.54-2.49-1.89,8.11-4.4,15.79-7.27,23.36ZM641.1,268.42c-7.6,20.6-18.26,39.35-34.05,54.87-19.31,18.98-42.81,29.56-69.3,34.13-17.6,3.04-35.28,3.46-53,1.89-32.71-2.89-61.67-14.85-86.69-36.23-11.55-9.87-21.99-20.86-32.76-31.52-9.12-9.03-18.23-18.19-29.63-24.31-27.61-14.83-55.24-14.37-82.46,1.16-13.37,7.63-23.99,18.4-33.55,30.31-.66.82-1.06,1.95-2.55,2.35,2.49-9.99,5.61-19.58,9.52-28.91,9.55-22.77,23.02-42.65,42.97-57.72,17.26-13.05,36.86-20.51,58.05-24.05,7.1-1.19,14.3-1.82,21.46-2.71h23.43c5.28.65,10.56,1.27,15.84,1.96,28.7,3.74,54.08,15.04,76.33,33.46,10.75,8.89,20.54,18.8,30.32,28.72,10.09,10.24,20.25,20.43,32.64,28.01,15.09,9.21,31.61,12.49,49.04,11.04,25.79-2.13,45.42-15.72,62.2-34.27,2.89-3.2,5.58-6.58,9.03-10.66-1.92,8.3-4.25,15.44-6.84,22.48Z"/>
                <path class="tailwind-dark" d="M372.54,187.68c5.28.65,10.56,1.27,15.83,1.95,28.7,3.74,54.08,15.04,76.33,33.46,10.75,8.9,20.54,18.8,30.32,28.72,10.09,10.24,20.25,20.43,32.64,28.01,15.08,9.22,31.61,12.49,49.03,11.05,25.79-2.13,45.42-15.72,62.2-34.28,2.89-3.2,5.59-6.58,9.04-10.66-1.93,8.31-4.25,15.44-6.85,22.48-7.6,20.6-18.25,39.35-34.04,54.87-19.31,18.98-42.81,29.56-69.31,34.13-17.6,3.04-35.28,3.46-52.99,1.89-32.71-2.9-61.68-14.85-86.7-36.23-11.55-9.87-21.99-20.86-32.75-31.52-9.12-9.03-18.23-18.19-29.63-24.31-27.61-14.83-55.24-14.37-82.46,1.16-13.36,7.63-23.99,18.4-33.54,30.3-.66.82-1.06,1.95-2.55,2.35,2.49-9.99,5.61-19.58,9.52-28.91,9.54-22.78,23.02-42.65,42.96-57.73,17.26-13.04,36.86-20.51,58.05-24.05,7.11-1.19,14.31-1.82,21.46-2.71,7.81,0,15.62,0,23.43,0Z"/>
                <path class="tailwind-dark" d="M73.05,473.7c11.02-40.82,29.66-75.62,68.01-96.53,15.23-8.3,31.66-12.96,48.82-15.18,12.64-1.63,25.29-2.01,38.03-1.44,35.99,1.61,67.85,13.51,95.28,37.01,11.02,9.45,21.01,19.95,31.29,30.15,8.39,8.32,16.86,16.57,26.82,23.07,27.63,18.05,63.34,17.27,91.61-1.99,10.98-7.48,20.15-16.83,28.41-27.15.67-.83,1.1-1.93,2.54-2.49-1.89,8.11-4.4,15.8-7.27,23.37-7.63,20.11-18.2,38.41-33.7,53.57-17.18,16.81-37.83,27.27-61.23,32.29-28.41,6.1-56.86,5.93-85.19-.63-26.17-6.05-48.41-19.34-68.12-37.25-11.15-10.13-21.4-21.15-32.23-31.59-8.77-8.46-17.85-16.7-29.07-21.69-29.2-12.99-57.23-10.49-83.72,7.53-10.6,7.21-19.39,16.34-27.39,26.29-.56.69-1.12,1.39-1.7,2.06-.13.15-.38.2-1.2.6Z"/>
            </g>
        </svg>
    );
};

export default SvgTailwind;